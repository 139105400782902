<template>
  <div
    class="boxwrap-contents"
  >
  <!-- :style="`height: ${this.boxwrapConfig.contents_h}px`" -->
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'

//= mixins
import { ready } from '@/assets/js/mixins/base/Ready'
import { getStyles } from '@/assets/js/mixins/base/GetStyles'
import { getApp } from '@/assets/js/mixins/common/GetApp'

export default {
  name: 'boxwrap-contents',

  inject: {
    boxwrapConfig: {}
  },

  mixins: [ ready, getStyles, getApp ],

  watch: {
    'getApp.drawer': function () {
      this.getContentsHeight()
    },

    showStatistics () {
      this.getContentsHeight()
    }
  },

  computed: {
    ...mapState(['showStatistics'])
  },


  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getContentsHeight)
      this.getContentsHeight()
    })
  },

  methods: {
    getContentsHeight () {
      let padding = this.getComputedStyles('paddingTop', this.$parent.$el) + this.getComputedStyles('paddingBottom', this.$parent.$el)

      this.$loaded(() => {
        this.boxwrapConfig.contents_h = this.boxwrapConfig.boxwrap_h - this.boxwrapConfig.toolbar_h - this.boxwrapConfig.header_h - padding
      }, 1000)
    }
  }
}
</script>
